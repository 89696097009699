// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAXMKRF-AJibdTt1b2e8Qd-ezDJDae7t8w",
  authDomain: "gasbillnew3rd.firebaseapp.com",
  databaseURL: "https://gasbillnew3rd-default-rtdb.firebaseio.com",
  projectId: "gasbillnew3rd",
  storageBucket: "gasbillnew3rd.appspot.com",
  messagingSenderId: "152913568707",
  appId: "1:152913568707:web:1471c90459e59e9cfc9376",
  measurementId: "G-FN89VYHMN7"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };